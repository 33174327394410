import Cookies from "js-cookie"
import { type FlagValuesType, decrypt, FlagOverridesType } from "@vercel/flags"
import { useState, useEffect } from "react"

// When adding a new flag, make sure to also add it to /api/vercel/flags
// so it gets rendered correctly in the Vercel UI
const defaultFlags: FlagValuesType = {
  showVesting: true,
  showPreclearance: true,
  showCfoToolbox: true,
  showCompliance: true,
  showInsights: true,
  enablePartnerEntities: true,
  enableFinancialRoadmap: false,
}

export const useFlags = () => {
  const [flags, setFlags] = useState(defaultFlags)

  useEffect(() => {
    const fetchFlags = async () => {
      const overrideCookie = Cookies.get("vercel-flag-overrides")
      const overrides = overrideCookie
        ? await decrypt<FlagOverridesType>(overrideCookie, process.env.NEXT_PUBLIC_FLAGS_SECRET)
        : {}

      Object.assign(flags, overrides)
      setFlags(flags)
    }

    fetchFlags()
  }, [])

  return flags
}
